// src/components/ThirdSection.js
import React from 'react';
import styled from 'styled-components';
import thirdImage from '../assets/images/img2.jpg'; // Make sure you have this image

const ThirdSectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 2rem;
  background-color: #e9ecef; // Adjust the color to match the theme
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const TextContent = styled.div`
  flex: 1;
  padding: 1rem;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333;
`;

const Description = styled.p`
  font-size: 1.1rem;
  color: #666;
  line-height: 1.6;
  margin-bottom: 2rem;
`;

const LearnMoreButton = styled.button`
  background-color: #3277A1;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  padding: 1rem;
  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
`;

const ThirdSection = () => (
    <ThirdSectionContainer>
        <ContentWrapper>
            <TextContent>
                <Title>The Threats Are Formidable - So Are Our Solutions</Title>
                <Description>
                    It is no longer a matter of if, but when. No organization, industry, or individual is exempt from cyber security threats. We’ve seen our threat vectors grow exponentially while the intent of bad actors has shifted from harmless annoyances and inconveniences to a multi-billion-dollar industry rife with profit. Our data, employees, and systems are under constant attack. Cybral Technologies is here to help protect your most valuable assets and ultimately your business. Don’t wait for “when”, stay ahead of the problem.
                </Description>
                <LearnMoreButton>Learn More</LearnMoreButton>
            </TextContent>
            <ImageContainer>
                <img src={thirdImage} alt="The Threats Are Formidable - So Are Our Solutions" />
            </ImageContainer>
        </ContentWrapper>
    </ThirdSectionContainer>
);

export default ThirdSection;
