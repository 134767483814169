// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --primary-bg: #102036;
    --secondary-color: #337BA5;
    --text-primary: #102036;
    --text-secondary: #ffffff;
}

body {
    background-color: var(--primary-bg);
    color: var(--text-secondary);
}

a {
    color: var(--secondary-color);
    transition: color 0.3s ease;
}

a:hover {
    color: var(--text-primary);
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/colors.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,0BAA0B;IAC1B,uBAAuB;IACvB,yBAAyB;AAC7B;;AAEA;IACI,mCAAmC;IACnC,4BAA4B;AAChC;;AAEA;IACI,6BAA6B;IAC7B,2BAA2B;AAC/B;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[":root {\r\n    --primary-bg: #102036;\r\n    --secondary-color: #337BA5;\r\n    --text-primary: #102036;\r\n    --text-secondary: #ffffff;\r\n}\r\n\r\nbody {\r\n    background-color: var(--primary-bg);\r\n    color: var(--text-secondary);\r\n}\r\n\r\na {\r\n    color: var(--secondary-color);\r\n    transition: color 0.3s ease;\r\n}\r\n\r\na:hover {\r\n    color: var(--text-primary);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
