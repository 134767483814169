import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styled from 'styled-components';
import firstImage from '../assets/images/Header-img-1.jpg';
import secondImage from '../assets/images/Header-img-2.jpg';
import '../assets/styles/colors.css'; // Import the centralized CSS file

const CarouselContainer = styled.section`
  background-color: var(--primary-bg); /* Use primary background color */
`;

const StyledCarousel = styled(Carousel)`
  .carousel .slide {
    background: none;
    position: relative; /* Make the slide container relative for absolute positioning */
  }

  .carousel .slide img {
    width: 100%;
    height: 520px;
    object-fit: cover;
  }

  .carousel .carousel-status {
    display: none; /* Hide the carousel status (e.g., "1 of 3") */
  }
`;

const ImageText = styled.div`
  position: absolute; /* Position text over the image */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust centering */
  width: 100%; /* Ensure text is centered and doesn't overflow */
  text-align: center; /* Center text horizontally */
`;

const MainTitle = styled.h1`
  font-size: 5vw; /* Responsive font size */
  font-weight: bold;
  margin: 0;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5); /* Add a slight shadow for better readability */
  line-height: 1.1; /* Adjust line-height for better spacing */

  @media (max-width: 1200px) {
    font-size: 4vw; /* Adjust for medium screens */
  }

  @media (max-width: 768px) {
    font-size: 6vw; /* Adjust for small screens */
  }

  @media (max-width: 480px) {
    font-size: 8vw; /* Adjust for extra small screens */
  }
`;

const SubTitle = styled.div`
  font-size: 2vw; /* Responsive font size */
  margin-top: 1rem;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5); /* Add a slight shadow for better readability */
  text-align: left; /* Align text to the left */
  max-width: 100%; /* Ensure the subtitle does not exceed the container width */
  position: absolute; /* Position subtitle absolutely */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust for centering */

  @media (max-width: 1200px) {
    font-size: 1.5vw; /* Adjust for medium screens */
  }

  @media (max-width: 768px) {
    font-size: 2.5vw; /* Adjust for small screens */
  }

  @media (max-width: 480px) {
    font-size: 4vw; /* Adjust for extra small screens */
  }
`;

const CarouselSection = () => (
    <CarouselContainer>
        <StyledCarousel showThumbs={false} autoPlay infiniteLoop>
            <div>
                <img src={firstImage} alt="Slide 1" />
                <ImageText>
                    <MainTitle>
                        Welcome To Cybral <br />
                        Technologies
                    </MainTitle>
                    <SubTitle>Managed IT Services</SubTitle>
                </ImageText>
            </div>
            {/*<div>*/}
            {/*    <img src={secondImage} alt="Slide 2" />*/}
            {/*    /!* If you want text on the second image as well, add another <ImageText> element here *!/*/}
            {/*    /!*<ImageText>*/}
            {/*        <MainTitle>Another Slide Title</MainTitle>*/}
            {/*        <SubTitle>Another Subtitle</SubTitle>*/}
            {/*    </ImageText>*!/*/}
            {/*</div>*/}
        </StyledCarousel>
        {/* Uncomment and modify the footer if needed */}
        {/* <CarouselFooter>
            <FooterLink href="/security-solutions">Security Solutions</FooterLink>
            <FooterLink href="/data-center">Data Center</FooterLink>
            <FooterLink href="/contact-us">Contact Us</FooterLink>
        </CarouselFooter> */}
    </CarouselContainer>
);

export default CarouselSection;
