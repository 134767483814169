// src/components/FourthSection.js
import React from 'react';
import styled from 'styled-components';
import fourthImage from '../assets/images/img3.jpg'; // Make sure you have this image

const FourthSectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 2rem;
  background-color: #ffffff; // Updated to white
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  padding: 1rem;
  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
`;

const TextContent = styled.div`
  flex: 1;
  padding: 1rem;
  text-align: left;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333;
`;

const Description = styled.p`
  font-size: 1.1rem;
  color: #666;
  line-height: 1.6;
  margin-bottom: 2rem;
`;

const LearnMoreButton = styled.button`
  background-color: #3277A1;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const FourthSection = () => (
    <FourthSectionContainer>
        <ContentWrapper>
            <ImageContainer>
                <img src={fourthImage} alt="The Data Center Is Rapidly Evolving – Our Solutions Help You Keep Pace" />
            </ImageContainer>
            <TextContent>
                <Title>The Data Center Is Rapidly Evolving – Our Solutions Help You Keep Pace</Title>
                <Description>
                    The data centers that deliver your applications and services are globally distributed and virtualization is king. Cybral Technologies understands the shift that is taking place with how we host and deliver business applications and services. We know that lowering IT operational costs, increasing time to market, and maintaining high performance is critical to your business.
                </Description>
                <LearnMoreButton>Learn More</LearnMoreButton>
            </TextContent>
        </ContentWrapper>
    </FourthSectionContainer>
);

export default FourthSection;
