import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';
import logo from '../assets/images/logo.png';
import '../assets/styles/colors.css'; // Import the centralized CSS file

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  background-color: var(--primary-bg);
  position: relative;
  z-index: 1000;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Logo = styled.img`
  height: 50px;

  @media (max-width: 768px) {
    height: 40px;
  }
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 1rem;
  position: relative;

  @media (max-width: 992px) {
    flex: 1;
    justify-content: center;
  }

  @media (max-width: 768px) {
    display: none;
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;
  }
`;

const NavLink = styled.div`
  position: relative;
  display: inline-block;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const NavLinkText = styled(Link)`
  text-decoration: none;
  color: var(--text-secondary);
  font-weight: 600;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: color 0.3s ease, background-color 0.3s ease;
  display: block;

  &:hover {
    color: var(--text-primary);
    background-color: var(--text-secondary);
  }

  @media (max-width: 768px) {
    padding: 1rem;
    text-align: center;
  }
`;

const SubMenu = styled.div`
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: var(--text-secondary);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  min-width: 200px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  z-index: 10;

  ${NavLink}:hover & {
    display: block;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  @media (max-width: 768px) {
    position: static;
    box-shadow: none;
    opacity: 1;
    visibility: visible;
    transform: none;
    display: block;
  }
`;

const SubMenuLink = styled(HashLink)`
  display: block;
  padding: 0.75rem 1rem;
  text-decoration: none;
  color: var(--text-primary);
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #f0f0f0;
    color: var(--secondary-color);
  }
`;

const ContactInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 992px) {
    display: none;
  }

  @media (max-width: 768px) {
    margin-top: 1rem;
    flex-direction: column;
    text-align: center;
  }
`;

const PhoneNumber = styled.span`
  font-size: 0.875rem;
  color: var(--text-secondary);

  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
`;

const SocialIcon = styled.a`
  color: var(--text-secondary);
  font-size: 1.5rem;
  margin-left: 0.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: #ccc;
  }

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

const MenuToggle = styled.button`
  display: none;
  background: var(--text-secondary);
  border: none;
  font-size: 1.5rem;
  color: var(--primary-bg);
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  @media (max-width: 768px) {
    display: block;
  }
`;

const MobileNavLinks = styled.nav`
  display: none;
  flex-direction: column;
  width: 100%;
  background: var(--primary-bg);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;

  ${(props) => props.isOpen && `
    display: flex;
  `}
`;

const MobileNavLink = styled(HashLink)`
  text-decoration: none;
  color: var(--text-secondary);
  padding: 1rem;
  border-bottom: 1px solid #ddd;
  display: block;
  text-align: center;

  &:hover {
    color: var(--text-primary);
    background-color: var(--text-secondary);
  }
`;

const Header = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    return (
        <HeaderContainer>
            <Link to="/">
                <Logo src={logo} alt="Cybral Technologies Logo" />
            </Link>
            <MenuToggle onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}>
                <i className="fas fa-bars"></i>
            </MenuToggle>
            <NavLinks>
                <NavLink>
                    <NavLinkText to="/">Home</NavLinkText>
                </NavLink>
                <NavLink>
                    <NavLinkText to="/about">About</NavLinkText>
                </NavLink>
                <NavLink>
                    <NavLinkText to="/services">Services</NavLinkText>
                    {/*<SubMenu>*/}
                    {/*    <SubMenuLink to="/services#cloud">Cloud Services (AWS, Azure, GCP)</SubMenuLink>*/}
                    {/*    <SubMenuLink to="/services#network">Network Infrastructure Maintenance</SubMenuLink>*/}
                    {/*    <SubMenuLink to="/services#virtualization">Virtualization Technology</SubMenuLink>*/}
                    {/*    <SubMenuLink to="/services#vulnerability">Vulnerability Assessment</SubMenuLink>*/}
                    {/*    <SubMenuLink to="/services#database">Database Monitoring and Maintenance</SubMenuLink>*/}
                    {/*</SubMenu>*/}
                </NavLink>
                <NavLink>
                    <NavLinkText to="/security-solutions">Security Solutions</NavLinkText>
                    {/*<SubMenu>*/}
                    {/*    <SubMenuLink to="/security-solutions#network">Network Security</SubMenuLink>*/}
                    {/*    <SubMenuLink to="/security-solutions#email">Email Security</SubMenuLink>*/}
                    {/*    <SubMenuLink to="/security-solutions#endpoint">Endpoint Security</SubMenuLink>*/}
                    {/*    <SubMenuLink to="/security-solutions#cloud">Cloud Security</SubMenuLink>*/}
                    {/*    <SubMenuLink to="/security-solutions#data">Data Security</SubMenuLink>*/}
                    {/*    <SubMenuLink to="/security-solutions#iam">Identity & Privilege Access Management</SubMenuLink>*/}
                    {/*    <SubMenuLink to="/security-solutions#siem">SIEM Solutions</SubMenuLink>*/}
                    {/*    <SubMenuLink to="/security-solutions#soar">SOAR Solutions</SubMenuLink>*/}
                    {/*</SubMenu>*/}
                </NavLink>
                <NavLink>
                    <NavLinkText to="/contact">Contact</NavLinkText>
                </NavLink>
            </NavLinks>
            <MobileNavLinks isOpen={isMobileMenuOpen}>
                <MobileNavLink to="/">Home</MobileNavLink>
                <MobileNavLink to="/about">About</MobileNavLink>
                {/*<MobileNavLink to="/security-solutions#network">Network Security</MobileNavLink>*/}
                {/*<MobileNavLink to="/security-solutions#email">Email Security</MobileNavLink>*/}
                {/*<MobileNavLink to="/security-solutions#endpoint">Endpoint Security</MobileNavLink>*/}
                {/*<MobileNavLink to="/security-solutions#cloud">Cloud Security</MobileNavLink>*/}
                {/*<MobileNavLink to="/security-solutions#data">Data Security</MobileNavLink>*/}
                {/*<MobileNavLink to="/security-solutions#iam">Identity & Privilege Access Management</MobileNavLink>*/}
                {/*<MobileNavLink to="/security-solutions#siem">SIEM Solutions</MobileNavLink>*/}
                {/*<MobileNavLink to="/security-solutions#soar">SOAR Solutions</MobileNavLink>*/}
                {/*<MobileNavLink to="/services#cloud">Cloud Services (AWS, Azure, GCP)</MobileNavLink>*/}
                {/*<MobileNavLink to="/services#network">Network Infrastructure Maintenance</MobileNavLink>*/}
                {/*<MobileNavLink to="/services#virtualization">Virtualization Technology</MobileNavLink>*/}
                {/*<MobileNavLink to="/services#vulnerability">Vulnerability Assessment</MobileNavLink>*/}
                {/*<MobileNavLink to="/services#database">Database Monitoring and Maintenance</MobileNavLink>*/}
            </MobileNavLinks>
            <ContactInfo>
                <SocialIcon href="#" target="_blank"><i className="fab fa-facebook-f"></i></SocialIcon>
                <SocialIcon href="#" target="_blank"><i className="fab fa-twitter"></i></SocialIcon>
                <SocialIcon href="https://www.linkedin.com/company/cybral-technologies/" target="_blank"><i className="fab fa-linkedin"></i></SocialIcon>
            </ContactInfo>
        </HeaderContainer>
    );
};

export default Header;
