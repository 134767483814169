// src/pages/About.js
import React from 'react';
import styled from 'styled-components';
import AboutUs from "../components/AboutUs";

const AboutContainer = styled.div`
  /* Add your styles here */
  background-color: white;
`;

const About = () => (
    <AboutContainer>
        <AboutUs/>
    </AboutContainer>
);

export default About;
