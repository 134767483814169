// src/components/WhatWeDo.js
import React from 'react';
import styled from 'styled-components';
import whatWeDoImage from '../assets/images/img1.jpg'; // Make sure you have this image
import '../assets/styles/colors.css'; // Import the centralized CSS file
const WhatWeDoSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 2rem;
  background-color: #f9f9f9;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  margin-bottom: 3rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  padding: 1rem;
  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  padding: 1rem;
  text-align: left;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin: 0; /* Removed top margin */
  color: #333;
`;

const Description = styled.p`
  font-size: 1.1rem;
  color: #666;
  line-height: 1.6;
`;

const ServicesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1.5rem;
  width: 100%;
  max-width: 1200px;
`;

const Service = styled.div`
  flex: 1 0 30%;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    flex: 0 0 45%;
  }

  @media (max-width: 480px) {
    flex: 0 0 100%;
  }
`;

const Icon = styled.div`
  font-size: 2.5rem;
  margin-right: 1rem;
  color: #007BFF;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ServiceTitle = styled.h3`
  font-size: 1.5rem;
  color: #333;
  margin: 0;
`;

const WhatWeDo = () => (
    <WhatWeDoSection>
        <ContentWrapper>
            <ImageContainer>
                <img src={whatWeDoImage} alt="What We Do" />
            </ImageContainer>
            <ContentContainer>
                <Title>What We Do</Title>
                <Description>
                    We provide comprehensive security solutions to ensure the safety and integrity of your data and systems. Our expert team offers a wide range of services tailored to meet your specific needs.
                </Description>
            </ContentContainer>
        </ContentWrapper>
        <ServicesContainer>
            <Service>
                <Icon style={{ color: '#337BA5' }}><i className="fas fa-shield-alt"></i></Icon>
                <ServiceTitle style={{ color: '#102036' }}>Security Policies</ServiceTitle>
            </Service>
            <Service>
                <Icon style={{ color: '#337BA5' }}><i className="fas fa-exclamation-triangle"></i></Icon>
                <ServiceTitle style={{ color: '#102036' }}>Risk Management</ServiceTitle>
            </Service>
            <Service>
                <Icon style={{ color: '#337BA5' }}><i className="fas fa-balance-scale"></i></Icon>
                <ServiceTitle style={{ color: '#102036' }}>Regulatory Compliance</ServiceTitle>
            </Service>
            <Service>
                <Icon style={{ color: '#337BA5' }}><i className="fas fa-life-ring"></i></Icon>
                <ServiceTitle style={{ color: '#102036' }}>Incident Response</ServiceTitle>
            </Service>
            <Service>
                <Icon style={{ color: '#337BA5' }}><i className="fas fa-chalkboard-teacher"></i></Icon>
                <ServiceTitle style={{ color: '#102036' }}>Education & Awareness</ServiceTitle>
            </Service>
            <Service>
                <Icon style={{ color: '#337BA5' }}><i className="fas fa-chart-line"></i></Icon>
                <ServiceTitle style={{ color: '#102036' }}>Reporting & Roadmapping</ServiceTitle>
            </Service>
        </ServicesContainer>
    </WhatWeDoSection>
);

export default WhatWeDo;
