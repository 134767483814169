// src/assets/styles/globalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'Arial', sans-serif !important;
  }
  *, *::before, *::after {
    box-sizing: inherit;
  }
  h1, h2, h3, h4, h5, h6, p, a, span, div {
    font-family: inherit; /* Ensures all text elements use the global font */
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  h3 {
    margin-bottom: 0.5rem;
  }

  /* Add other global styles here */
`;

export default GlobalStyle;
