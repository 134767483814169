// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Header from './components/Header';
import Footer from './components/Footer';
import GlobalStyle from './assets/styles/globalStyles';
import ContactUs from "./components/ContactUs";
import SecuritySolutions from "./pages/SecuritySolutions";
import Services from "./pages/Services";

function App() {
    return (
        <Router>
            <GlobalStyle />
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/security-solutions" element={<SecuritySolutions />} />
                <Route path="/Services" element={<Services />} />
                {/* Add other routes as necessary */}
            </Routes>
            <ContactUs />
            <Footer />
        </Router>
    );
}

export default App;
