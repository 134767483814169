// src/pages/Home.js
import React from 'react';
import styled from 'styled-components';
import CarouselSection from "../components/CarouselSection";
import WhatWeDo from "../components/WhatWeDo";
import ThirdSection from "../components/ThirdSection";
import FourthSection from "../components/FourthSection";
import VendorsSlider from "../components/VendorsSlider";
import ClientsSlider from "../components/ClientsSlider";

const HomeContainer = styled.div`
  /* Add your styles here */
`;

const Home = () => (
    <HomeContainer>
        <CarouselSection />
        <WhatWeDo />
        <ThirdSection />
        <FourthSection />
        <VendorsSlider />
        <ClientsSlider />
    </HomeContainer>
);

export default Home;
