// src/pages/AboutUs.js
import React from 'react';
import styled from 'styled-components';
import backgroundImage from '../assets/images/Header-img-1.jpg'; // Add a suitable background image

const HeroSection = styled.section`
  height: 60vh;
  background: url(${backgroundImage}) no-repeat center center/cover;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
`;

const HeroTitle = styled.h1`
  font-size: 4rem;
  margin: 0;
`;

const AboutUsSection = styled.section`
  padding: 4rem 2rem;
  background-color: #fff;
  color: #333;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  line-height: 1.6;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #102036;
`;

const SubTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #337BA5;
`;

const Description = styled.p`
  font-size: 1.1rem; /* Standardized font size for all paragraphs */
  margin-bottom: 2rem;
  text-align: left;
  line-height: 1.8;
`;

const List = styled.ul`
  text-align: left;
  padding-left: 1.5rem;
  list-style: none;
`;

const ListItem = styled.li`
  margin-bottom: 1rem;
  &:before {
    content: '•';
    color: #337BA5;
    display: inline-block;
    width: 1rem;
    margin-left: -1rem;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
`;

const Button = styled.a`
  background-color: #337BA5;
  color: white;
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #285e7d;
  }
`;

const AboutUs = () => (
    <>
        <HeroSection>
            <HeroTitle>About Us</HeroTitle>
        </HeroSection>
        <AboutUsSection>
            <Title>Securing Your Digital Future</Title>
            <Description>
                At Cybral Technologies, we understand that in today’s digital world, security isn’t just an option—it’s a necessity. Founded in 2024, our mission is to protect your business from ever-evolving cyber threats and to ensure your digital infrastructure remains robust, reliable, and resilient.
            </Description>
            <SubTitle>Who We Are</SubTitle>
            <Description>
                We are a team of passionate IT security professionals with a shared commitment to safeguarding your digital assets. Our diverse team brings together years of experience in cybersecurity, IT management, and risk mitigation. From seasoned experts in network security to innovative thinkers in threat analysis, we combine our skills and knowledge to offer comprehensive protection against cyber threats.
            </Description>
            <SubTitle>Our Mission</SubTitle>
            <Description>
                Our mission is clear: to provide cutting-edge IT security solutions tailored to meet the unique needs of each of our clients. We are dedicated to delivering not just services, but peace of mind. By employing the latest technologies and practices, we ensure that your systems are protected against both current and future threats.
            </Description>
            <SubTitle>Our Approach</SubTitle>
            <Description>
                At Cybral Technologies, we believe that effective security is proactive, not reactive. Our approach involves:
            </Description>
            <List>
                <ListItem><strong>In-Depth Assessments:</strong> We start with a thorough evaluation of your existing security posture to identify vulnerabilities and areas for improvement.</ListItem>
                <ListItem><strong>Tailored Solutions:</strong> No two businesses are the same. We customize our solutions to fit your specific needs, whether it’s through managed services, consulting, or incident response.</ListItem>
                <ListItem><strong>Continuous Monitoring:</strong> Our work doesn’t stop once the initial setup is complete. We offer ongoing monitoring and support to adapt to new threats and ensure continuous protection.</ListItem>
                <ListItem><strong>Education & Training:</strong> We empower your team with the knowledge and tools they need to recognize and prevent potential security threats.</ListItem>
            </List>
            <SubTitle>Our Values</SubTitle>
            <Description>
                <strong>Integrity:</strong> We are transparent and honest in all our dealings, ensuring that you understand the solutions we provide and why they’re necessary.
                <br />
                <strong>Excellence:</strong> We strive for excellence in everything we do, from the technology we use to the customer service we provide.
                <br />
                <strong>Innovation:</strong> The cyber threat landscape is always changing, and so are we. We continuously research and implement the latest advancements in security to stay ahead of potential threats.
                <br />
                <strong>Customer-Centricity:</strong> Your security is our top priority. We listen to your concerns, understand your needs, and work collaboratively to provide the best solutions.
            </Description>
            <SubTitle>Why Choose Us?</SubTitle>
            <Description>
                Choosing Cybral Technologies means partnering with a team that is as committed to your security as you are. Our proven track record of successfully defending businesses against cyber threats and our dedication to continuous improvement set us apart. We don’t just offer a service; we offer a partnership in safeguarding your digital assets.
            </Description>
            <SubTitle>Get in Touch</SubTitle>
            <Description>
                Ready to take the next step in securing your digital infrastructure? Contact us today to learn how we can help protect your business and provide the peace of mind you deserve.
            </Description>
            <ButtonContainer>
                <Button href="/contact-us">Executive or Technical Briefing</Button>
                <Button href="/contact-us">Cyber Risk & Security</Button>
            </ButtonContainer>
        </AboutUsSection>
    </>
);

export default AboutUs;
