// src/pages/SecuritySolutions/SecuritySolutions.js
import React from 'react';
import styled from 'styled-components';
import Breadcrumb from '../components/Breadcrumb';
import headerImage from '../assets/images/horizontal_img2.jpg';
import networkImage from '../assets/images/img9.jpg';
import emailImage from '../assets/images/img10.jpg';
import endpointImage from '../assets/images/img11.jpg';
import cloudImage from '../assets/images/img12.jpg';
import dataImage from '../assets/images/img13.jpg';
import iamImage from '../assets/images/img14.jpg';
import siemImage from '../assets/images/img15.jpg';
import soarImage from '../assets/images/img16.jpg';


const PageContainer = styled.div`
  background-color: #fff;
  color: #333;
`;

const HeaderImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  background: url(${headerImage}) no-repeat center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
`;

const HeaderTitle = styled.h1`
  color: #fff;
  font-size: 3rem;
`;

const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 4rem 2rem;
  max-width: 1200px;
  margin: 0 auto;

  &:nth-child(even) {
    flex-direction: row-reverse;
  }
`;

const SectionImage = styled.img`
  width: 100%;
  max-width: 500px;
  margin: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const SectionContent = styled.div`
  flex: 1;
  max-width: 600px;
  margin: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #102036;
`;

const SectionDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  color: #666;
`;

const SecuritySolutions = () => {
    return (
        <PageContainer>
            <HeaderImageContainer>
                <TitleOverlay>
                    <HeaderTitle>Security Solutions</HeaderTitle>
                </TitleOverlay>
                <Breadcrumb />
            </HeaderImageContainer>

            <Section  id="network">
                <SectionImage src={networkImage} alt="Network Security" />
                <SectionContent>
                    <SectionTitle>Network Security</SectionTitle>
                    <SectionDescription>
                        At Cybral Technologies, we're committed to using cutting-edge network security solutions to safeguard your digital assets. Our goal is to implement and protect your company from ever-changing cyberattacks while making sure all of your activities are safe, secure, and available.
                    </SectionDescription>
                </SectionContent>
            </Section>

            <Section  id="email">
                <SectionImage src={emailImage} alt="Email Security" />
                <SectionContent>
                    <SectionTitle>Email Security</SectionTitle>
                    <SectionDescription>
                        Email is still a vital tool for communication in today's digital world, both personally and professionally. But now more than ever, protecting your email is crucial due to the growing sophistication of cyberattacks. We're dedicated to assisting you in comprehending and putting into practice safe email practices.
                    </SectionDescription>
                </SectionContent>
            </Section>

            <Section id="endpoint">
                <SectionImage src={endpointImage} alt="Endpoint Security" />
                <SectionContent>
                    <SectionTitle>Endpoint Security (EDR/XDR)</SectionTitle>
                    <SectionDescription>
                        In an era where cyber threats are ever-evolving, securing the devices that connect to your network is crucial. Endpoint security is the practice of protecting endpoints—such as computers, smartphones, tablets, and other devices—from cyber threats. We provide comprehensive endpoint security solutions to ensure that your devices and data are protected against potential risks.
                    </SectionDescription>
                </SectionContent>
            </Section>

            <Section id="cloud">
                <SectionImage src={cloudImage} alt="Cloud Security" />
                <SectionContent>
                    <SectionTitle>Cloud Security</SectionTitle>
                    <SectionDescription>
                        As businesses increasingly migrate to cloud environments, ensuring the security of cloud-based data and applications is more critical than ever. We specialize in providing robust cloud security solutions designed to protect your valuable assets in the cloud. Our comprehensive approach ensures that your data remains secure, accessible, and compliant.
                    </SectionDescription>
                </SectionContent>
            </Section>

            <Section id="data">
                <SectionImage src={dataImage} alt="Data Security" />
                <SectionContent>
                    <SectionTitle>Data Security</SectionTitle>
                    <SectionDescription>
                        In today’s data-driven world, securing your data is paramount. We provide comprehensive data security solutions to protect your sensitive information from breaches, theft, and loss. Our advanced technologies and expert strategies ensure that your data remains safe, confidential, and compliant.
                    </SectionDescription>
                </SectionContent>
            </Section>

            <Section id="iam" >
                <SectionImage src={iamImage} alt="Identity & Privilege Access Management" />
                <SectionContent>
                    <SectionTitle>Identity & Privilege Access Management</SectionTitle>
                    <SectionDescription>
                        In today’s interconnected world, managing who has access to your systems and data is crucial for maintaining security and operational efficiency. We offer comprehensive Identity and Privilege Access Management (IAM) solutions to ensure that the right individuals have the right access at the right time.
                    </SectionDescription>
                </SectionContent>
            </Section>

            <Section id="siem">
                <SectionImage src={siemImage} alt="SIEM" />
                <SectionContent>
                    <SectionTitle>SIEM Solutions</SectionTitle>
                    <SectionDescription>
                        In an era where cyber threats are constantly evolving, having a comprehensive view of your security landscape is essential. Our team will provide you with Security Information and Event Management (SIEM) solutions with real-time insights and advanced analytics to detect, respond to, and mitigate security threats effectively.
                    </SectionDescription>
                </SectionContent>
            </Section>

            <Section id="soar">
                <SectionImage src={soarImage} alt="SOAR Solutions" />
                <SectionContent>
                    <SectionTitle>SOAR Solutions</SectionTitle>
                    <SectionDescription>
                        In the fast-paced world of cybersecurity, efficiency and speed are key to managing and mitigating threats effectively. We will implement Security Orchestration, Automation, and Response (SOAR) solutions which are designed to enhance your security operations by integrating, automating, and optimizing your response to incidents.
                    </SectionDescription>
                </SectionContent>
            </Section>
        </PageContainer>
    );
};

export default SecuritySolutions;
