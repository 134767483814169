// src/components/Footer.js
import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: #333;
  color: #fff;
  padding: 4rem 2rem;
  text-align: center;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const FooterSections = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const FooterSection = styled.div`
  flex: 1;
  margin: 1rem;

  @media (min-width: 768px) {
    margin: 0 1.5rem;
  }
`;

const FooterTitle = styled.h4`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #fff;
`;

const FooterLink = styled.a`
  display: block;
  color: #ccc;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 1rem;

  &:hover {
    color: #fff;
    text-decoration: underline;
  }
`;

const FooterBottom = styled.div`
  margin-top: 2rem;
  border-top: 1px solid #444;
  padding-top: 1rem;
  font-size: 0.9rem;
  color: #ccc;
`;

const PhoneNumber = styled.p`
  font-size: 1.2rem;
  margin: 1rem 0;
  color: #fff;
`;

const Footer = () => (
    <FooterContainer>
        <FooterContent>
            <FooterSections>
                <FooterSection>
                    <FooterTitle>Company</FooterTitle>
                    <FooterLink href="#">About Us</FooterLink>
                    <FooterLink href="#">Careers</FooterLink>
                    <FooterLink href="#">Press</FooterLink>
                </FooterSection>
                <FooterSection>
                    <FooterTitle>Services</FooterTitle>
                    <FooterLink href="/services">Cloud Services</FooterLink>
                    <FooterLink href="/services">Network Infrastructure</FooterLink>
                    <FooterLink href="/services">Virtualization Technology</FooterLink>
                    <FooterLink href="/services">Vulnerability Assessment</FooterLink>
                    <FooterLink href="/services">Database Monitoring</FooterLink>
                </FooterSection>
                <FooterSection>
                    <FooterTitle>Contact</FooterTitle>
                    <FooterLink href="#">+923124394250</FooterLink>
                    <FooterLink href="#">info@cybraltechnologies.com</FooterLink>
                </FooterSection>
            </FooterSections>
            <FooterBottom>
                &copy; {new Date().getFullYear()} Cybral Technologies. All rights reserved.
            </FooterBottom>
        </FooterContent>
    </FooterContainer>
);

export default Footer;
