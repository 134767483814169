// src/components/Breadcrumb.js
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const BreadcrumbContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 1rem;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
`;

const Crumb = styled(Link)`
  color: #fff;
  text-decoration: none;
  margin: 0 0.5rem;
  &:hover {
    text-decoration: underline;
  }
  &:after {
    content: '/';
    margin-left: 0.5rem;
  }
  &:last-child:after {
    content: '';
  }
`;

const Breadcrumb = () => {
    return (
        <BreadcrumbContainer>
            <Crumb to="/">Home</Crumb>
            <Crumb to="/security-solutions">Security Solutions</Crumb>
        </BreadcrumbContainer>
    );
};

export default Breadcrumb;
