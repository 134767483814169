// src/pages/SecuritySolutions/SecuritySolutions.js
import React from 'react';
import styled from 'styled-components';
import Breadcrumb from '../components/Breadcrumb';
import headerImage from '../assets/images/horizontal_img1.jpg';
import networkImage from '../assets/images/img4.jpg';
import emailImage from '../assets/images/img5.jpg';
import endpointImage from '../assets/images/img6.jpg';
import cloudImage from '../assets/images/img7.jpg';
import dataImage from '../assets/images/img8.jpg';
import Bredcrumb1 from "../components/Bredcrumb1";


const PageContainer = styled.div`
  background-color: #fff;
  color: #333;
`;

const HeaderImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  background: url(${headerImage}) no-repeat center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
`;

const HeaderTitle = styled.h1`
  color: #fff;
  font-size: 3rem;
`;

const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 4rem 2rem;
  max-width: 1200px;
  margin: 0 auto;

  &:nth-child(even) {
    flex-direction: row-reverse;
  }
`;

const SectionImage = styled.img`
  width: 100%;
  max-width: 500px;
  margin: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const SectionContent = styled.div`
  flex: 1;
  max-width: 600px;
  margin: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #102036;
`;

const SectionDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  color: #666;
`;

const Services = () => {
    return (
        <PageContainer>
            <HeaderImageContainer>
                <TitleOverlay>
                    <HeaderTitle>Cybral Technologies Services</HeaderTitle>
                </TitleOverlay>
                <Bredcrumb1 />
            </HeaderImageContainer>

            <Section  id="cloud">
                <SectionImage src={networkImage} alt="Network Security" />
                <SectionContent>
                    <SectionTitle>Cloud Services (AWS, Azure, GCP)</SectionTitle>
                    <SectionDescription>
                        In today's digital landscape, leveraging the right cloud services is crucial for driving innovation, scalability, and operational efficiency. We provide comprehensive cloud solutions across Amazon Web Services (AWS), Microsoft Azure, and Google Cloud Platform (GCP). Our expertise in these leading cloud platforms ensures that you receive tailored solutions that meet your unique business needs.
                    </SectionDescription>
                </SectionContent>
            </Section>

            <Section  id="network">
                <SectionImage src={emailImage} alt="Email Security" />
                <SectionContent>
                    <SectionTitle>Network Infrastructure Maintenance</SectionTitle>
                    <SectionDescription>
                        A robust and reliable network infrastructure is the backbone of modern business operations. We specialize in providing comprehensive network infrastructure maintenance services to ensure that your network remains secure, efficient, available and resilient. Our proactive approach helps you avoid downtime, optimize performance, and protect your valuable data.
                    </SectionDescription>
                </SectionContent>
            </Section>

            <Section id="virtualization">
                <SectionImage src={endpointImage} alt="Virtualization Technology" />
                <SectionContent>
                    <SectionTitle>Virtualization Technology</SectionTitle>
                    <SectionDescription>
                        At Cybral Technologies, we empower businesses to optimize their IT operations, reduce costs, and improve scalability through innovative virtualization solutions. Discover how our technology can transform your IT infrastructure.
                    </SectionDescription>
                </SectionContent>
            </Section>

            <Section id="vulnerability">
                <SectionImage src={cloudImage} alt="Vulnerability Assessment" />
                <SectionContent>
                    <SectionTitle>Vulnerability Assessment</SectionTitle>
                    <SectionDescription>
                        We specialize in identifying and mitigating security risks in your IT infrastructure. Our team will thoroughly assess and perform vulnerability assessments to help safeguard your business against potential threats and ensure compliance with industry standards.
                    </SectionDescription>
                </SectionContent>
            </Section>

            <Section id="database">
                <SectionImage src={dataImage} alt="Database" />
                <SectionContent>
                    <SectionTitle>Database Monitoring and Maintenance</SectionTitle>
                    <SectionDescription>
                        We specialize in providing top-notch database monitoring and maintenance services. Our team of experienced professionals is dedicated to ensuring the health and performance of your databases, allowing you to focus on what you do best – running your business.
                    </SectionDescription>
                </SectionContent>
            </Section>
        </PageContainer>
    );
};

export default Services;
