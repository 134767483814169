// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/style.css'; // Import the CSS file
import App from './App';
import GlobalStyle from './assets/styles/globalStyles';
import '@fortawesome/fontawesome-free/css/all.min.css';  // Add this line

ReactDOM.render(
    <React.StrictMode>
        <GlobalStyle />
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
