// src/components/VendorsSlider.js
import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import logo1 from "../assets/images/Parent-logo.webp";
import logo2 from "../assets/images/Fornet-logo-600x185.webp";
import logo3 from "../assets/images/proofpoint";
import logo4 from "../assets/images/Arcsight.png";
import logo5 from "../assets/images/Splunk.png";
import logo6 from "../assets/images/Okta-Logo.jpg";
import logo7 from "../assets/images/Zabbix";
import logo8 from "../assets/images/Solarwinds";
import logo9 from "../assets/images/Manage Engine.png";
import logo10 from "../assets/images/NetSkope";
import logo11 from "../assets/images/CrowdStrike.png";
import logo12 from "../assets/images/Sentinel One.png";
import logo13 from "../assets/images/InfoBlox.png";

const VendorsContainer = styled.section`
  padding: 4rem 2rem;
  background-color: #E9ECEF;
  text-align: center;
`;

const SectionTitle = styled.h2`
  font-size: 2.8rem;
  margin-bottom: 2rem;
  color: #333;
`;

const VendorItem = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  margin: 0 0.5rem;
  border-radius: 5px;
  background-color: #fff;
  width: 100%; /* Full width for consistency */
  height: 150px; /* Fixed height for a consistent look */
  overflow: hidden; /* Ensures any overflow is hidden */
`;

const VendorLogo = styled.img`
  width: 80%; /* Take the full width of the container */
  height: 70%; /* Take the full height of the container */
  object-fit: contain; /* Contain the image while preserving aspect ratio */
  margin-bottom: 0.5rem; /* Add margin for spacing */
`;

const VendorName = styled.p`
  font-size: 1rem;
  color: #666;
  margin: 0;
  padding-top: 0.5rem; /* Add some space above the name */
`;

const VendorsSlider = () => {
    const vendors = [
        { name: "Palo Alto", logo: logo1 },
        { name: "Fortinet", logo: logo2 },
        { name: "Proofpoint", logo: logo3 },
        { name: "Arcsight", logo: logo4 },
        { name: "Splunk", logo: logo5 },
        { name: "OKTA", logo: logo6 },
        { name: "Zabbix", logo: logo7 },
        { name: "Solarwinds", logo: logo8 },
        { name: "Manage Engine", logo: logo9 },
        { name: "NetSkope", logo: logo10 },
        { name: "CrowdStrike", logo: logo11 },
        { name: "Sentinel One", logo: logo12 },
        { name: "InfoBlox", logo: logo13 },
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true, // Enables auto-moving slider
        autoplaySpeed: 2000, // Slider will change every 2 seconds
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };

    return (
        <VendorsContainer>
            <SectionTitle>Our Vendors</SectionTitle>
            <Slider {...settings}>
                {vendors.map((vendor, index) => (
                    <VendorItem key={index}>
                        <VendorLogo src={vendor.logo} alt={vendor.name} />
                        <VendorName>{vendor.name}</VendorName>
                    </VendorItem>
                ))}
            </Slider>
        </VendorsContainer>
    );
};

export default VendorsSlider;
