// src/components/ClientsSlider.js
import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import logo1 from '../assets/images/mga'
import logo2 from '../assets/images/Kleene.png'
import logo3 from '../assets/images/capitalgurus.png'
const ClientsContainer = styled.section`
  padding: 4rem 2rem;
  background-color: #ffffff;
  text-align: center;
`;

const SectionTitle = styled.h2`
  font-size: 2.8rem;
  margin-bottom: 2rem;
  color: #333;
`;

const ClientItem = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  margin: 0 0.5rem;
  border-radius: 5px;
  background-color: #fff;
`;

const ClientLogo = styled.img`
  max-width: 100%;
  height: 60px;
  object-fit: contain;
  margin-bottom: 0.5rem; /* Add margin for spacing */
`;

const ClientName = styled.p`
  font-size: 1rem;
  color: #666;
  margin: 0;
`;

const ClientsSlider = () => {
    const clients = [
        { name: "solutionsmga.com", logo: logo1 },
        { name: "kleene.ai", logo: logo2 },
        { name: "capitalgurus.com", logo: logo3 }
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,  // Enables auto-moving slider
        autoplaySpeed: 2000,  // Slider will change every 2 seconds
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,  // Show one slide at a time on smaller screens
                },
            },
        ],
    };

    return (
        <ClientsContainer>
            <SectionTitle>Our Clients</SectionTitle>
            <Slider {...settings}>
                {clients.map((client, index) => (
                    <ClientItem key={index}>
                        <ClientLogo src={client.logo} alt={client.name} />
                        <ClientName>{client.name}</ClientName>
                    </ClientItem>
                ))}
            </Slider>
        </ClientsContainer>
    );
};

export default ClientsSlider;
