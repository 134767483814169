// src/components/ContactUs.js
import React from 'react';
import styled from 'styled-components';
import emailjs from 'emailjs-com';

const ContactUsContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 2rem;
  background-color: #f7f9fc; // Light color according to the theme
`;

const Title = styled.h2`
  font-size: 2.8rem;
  margin-bottom: 1rem;
  color: #333;
  text-align: center;
`;

const Description = styled.p`
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 2rem;
  text-align: center;
  max-width: 800px; // Increased max-width
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 900px; // Increased max-width
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-top: 2rem;
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 1rem;
  color: #333;
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box;
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box;
  resize: vertical;
`;

const SubmitButton = styled.button`
  background-color: #3277A1;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 100%;
  max-width: 200px;
  margin-top: 1rem;

  &:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
`;

const ContactUs = () => {
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_exgon0l', 'template_nvgowzw', e.target, 'mkZugQBkfm3EyxPum')
            .then((result) => {
                alert('Message sent successfully');
            }, (error) => {
                alert('Error sending message');
            });
    };

    return (
        <ContactUsContainer>
            <Title>Contact Us</Title>
            <Description>
                Questions? Comments? Call us today at (+92)3124394250 or fill out the form below
            </Description>
            <FormContainer>
                <form onSubmit={sendEmail}>
                    <FormGrid>
                        <FormField>
                            <Label htmlFor="first-name">First Name</Label>
                            <Input type="text" id="first-name" name="first-name" required />
                        </FormField>
                        <FormField>
                            <Label htmlFor="last-name">Last Name</Label>
                            <Input type="text" id="last-name" name="last-name" required />
                        </FormField>
                        <FormField>
                            <Label htmlFor="email">Email Address</Label>
                            <Input type="email" id="email" name="email" required />
                        </FormField>
                        <FormField>
                            <Label htmlFor="phone">Phone Number</Label>
                            <Input type="tel" id="phone" name="phone" />
                        </FormField>
                    </FormGrid>
                    <FormField>
                        <Label htmlFor="message">Message</Label>
                        <Textarea id="message" name="message" rows="6" required />
                    </FormField>
                    <SubmitButton type="submit">Send Message</SubmitButton>
                </form>
            </FormContainer>
        </ContactUsContainer>
    );
};

export default ContactUs;
